<template>
<!-- <iframe src="https://d1santafe.arsconnect.com/"></iframe> -->
<div>
  <div class="spaceWrap">
  <!-- <iframe class="viewPort" src="https://ottawa-kiosk.arsconnect.com/">
  </iframe> -->
  <iframe id="myFrame" class="viewPort" src="https://miottawa.fidlar.com/MIOttawa/Apex.WebPortal/">
  </iframe>  
</div>
<v-btn @click="changeFrame">change</v-btn>

</div>


    
</template>

<script>
export default {
    name: 'kioskiFrame',
    methods:{
      changeFrame(){
            document.getElementsByClassName
            let iframe = document.getElementById('myFrame');
            let element = iframe.contentWindow.document.getElementsByClassName('docType');
            element.style.display = "none"
        }
    }
}
</script>

<style>
/* iframe {
  transform: scale(0.5);
  height: 1080px;
  width: 1920px;
} */

.spaceWrap { 
  width: 1000px; 
  height: 600px; 
  padding: 0; 
  overflow: hidden; 
}

.viewPort { 
  width: 1920px; 
  height: 1080px;   
}

.viewPort {
    -webkit-transform: scale(0.50);
    -webkit-transform-origin: 0 0;
}

</style>